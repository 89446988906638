<script>
export default {
  name: 'Loader',
};
</script>

<template>
  <div class="ripple invline-block relative w-20 h-20">
    <div class="absolute border-4 border-dark opacity-100 rounded-full"></div>
    <div class="absolute border-4 border-dark opacity-100 rounded-full"></div>
  </div>
</template>

<style scoped>
.ripple div {
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
