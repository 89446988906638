import { inject, reactive, getCurrentInstance } from '@vue/composition-api';

/**
 * Injection unique key to register router instance
 */
export const INJECTION_ROUTER_KEY = Symbol('vue-router-key');

/**
 * Provides the router object which has been injected in the App.vue
 */
export function useRouter() {
  return inject(INJECTION_ROUTER_KEY);
}

/**
 * @type {import('vue-router').Route}
 */
let currentRoute;

/**
 * Provides the current route from the router instance
 */
export function useRoute() {
  if (!currentRoute) {
    const instance = getCurrentInstance();
    if (!instance) {
      console.warn('no current vue instance');
      return currentRoute;
    }
    currentRoute = reactive({ ...instance?.proxy?.$route });
    const router = instance?.proxy?.$router;
    router.afterEach((routeTo) => Object.assign(currentRoute, routeTo));
  }
  return currentRoute;
}
