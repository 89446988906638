const USER_UPDATE_CURRENT_PROFILE = 'user/UPDATE_CURRENT_PROFILE';
const USER_UPDATE_DEFAULT_PROFILE_ID = 'user/UPDATE_DEFAULT_PROFILE_ID';
const USER_INIT_STATE = 'user/INIT_STATE';

const STORE_DEFAULT_PROFILE_ID = '--default-profile-id';

/**
 *
 * @param {Storage} local - local storage
 */
export default function createProfileSync(local) {
  /**
   * @param {import('node_modules/vuex/types/index').Store} store
   */
  return function profileSync(store) {
    store.subscribe((mutation) => {
      if (mutation.type === USER_UPDATE_CURRENT_PROFILE) {
        const defaultProfile = {
          id: mutation?.payload?.partyId,
          customerType: mutation?.payload?.partyType,
        };
        if (!defaultProfile?.id) {
          return;
        }
        local.setItem(STORE_DEFAULT_PROFILE_ID, JSON.stringify(defaultProfile));
      }
      if (mutation.type === USER_INIT_STATE) {
        const defaultProfile = JSON.parse(local.getItem(STORE_DEFAULT_PROFILE_ID));
        if (!defaultProfile?.id) {
          return;
        }
        store.commit(USER_UPDATE_DEFAULT_PROFILE_ID, defaultProfile?.id);
      }
    });
  };
}
