/* eslint-disable no-param-reassign */
import Cleave from 'cleave.js';

function bindDirective(el, binding) {
  if (!binding.value) {
    return;
  }
  el.cleave = new Cleave(el, binding.value);
}

export const DIRECTIVE_CLEAVE = 'cleave';

export default {
  bind: bindDirective,
  update(el, binding) {
    if (el.cleave) {
      el.cleave.destroy();
    }
    bindDirective(el, binding);
  },
  unbind(el) {
    if (el.cleave) {
      el.cleave.destroy();
    }
  },
};
