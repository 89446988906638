/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import * as artifactsApi from '@/api/artifacts';
import * as bnplApi from '@/api/bnpl-payments';
import * as routeNames from '@/lib/router';
import { ARTIFACT_GET, ARTIFACT_GET_FORCE, USER_GET_PROFILE } from '@/lib/store';
import { filterPaymentOptionsByDecisions, hasBNPL, isArtifactPaid } from '@/lib/artifacts';

/**
 * Get the details for the given artifact Id.
 * If the customer's artifactDetails can not be retrieved for any reason
 * route to the something went wrong page.
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @param {import('vue-router').NavigationGuardNext} next
 */
export async function handlePaidArtifact(to, from, next) {
  try {
    const { data } = await artifactsApi.getArtifactDetails(to.params.artifactId);
    to.params.artifactDetails = data?.artifactDetails;
    next();
  } catch (error) {
    next({
      name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
      params: { errorMessage: 'An issue occurred while processing your request.' },
    });
  }
}

/**
 * validates the artifact exists and is in a payable state.
 * User is not required.
 * route away if artifact is invalid.
 * attaches data to props props:
 * - artifactDetails: artifact details
 * - paymentOptions: payment options available
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function validateCardPaymentRequest(store) {
  /**
   * Card payment before enter method, expects param: artifactId
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    try {
      const artifactId = to?.params?.artifactId;
      const artifact = await store.dispatch(ARTIFACT_GET_FORCE, to.params.artifactId);

      if (isArtifactPaid(artifact)) {
        next({ name: routeNames.ROUTE_ARTIFACT_ALREADY_PAID, params: { artifactId } });
        return;
      }

      to.params.artifactDetails = artifact?.artifactDetails;
      to.params.paymentOptions = artifact?.paymentOptions;
      next();
    } catch (error) {
      next({
        name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
        params: { errorMessage: 'An issue processing your card payment' },
      });
    }
  };
}

/**
 * ensure we have a valid artifact and is in a payable state,
 * check if we have existing decisions and if user is logged in.
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function validatePaymentRequest(store) {
  /**
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    const artifactId = to?.params?.artifactId;
    const resp = await Promise.allSettled([
      store.dispatch(ARTIFACT_GET_FORCE, to?.params?.artifactId),
      store.dispatch(USER_GET_PROFILE),
    ]);

    const [artifact, profile] = resp;

    let decisionsExist;
    if (profile?.value?.partyId) {
      try {
        const { data } = await bnplApi.getPaymentPlanDecisionsByArtifactId({
          artifactId: to?.params?.artifactId,
          artifactType: artifact?.value?.artifactDetails?.artifactType,
          partyId: profile?.value?.partyId,
        });

        const bnplPaymentOptions = filterPaymentOptionsByDecisions(artifact?.value?.paymentOptions, data);

        if (hasBNPL(bnplPaymentOptions)) {
          decisionsExist = true;
        }
      } catch (error) {
        decisionsExist = false;
      }
    }

    try {
      if (artifact.status === 'rejected') {
        next({
          name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
          params: { errorMessage: 'An issue occurred while processing your request.' },
        });
        return;
      }

      if (isArtifactPaid(artifact.value)) {
        next({ name: routeNames.ROUTE_ARTIFACT_ALREADY_PAID, params: { artifactId } });
        return;
      }

      if (!hasBNPL(artifact?.value?.paymentOptions)) {
        next({ name: routeNames.ROUTE_CARD_PAYMENT, params: { artifactId } });
        return;
      }

      to.params.artifactDetails = artifact?.value?.artifactDetails;
      to.params.paymentOptions = artifact?.value?.paymentOptions;
      to.params.profile = profile?.value;
      to.params.decisionsExist = decisionsExist;
      next();
    } catch (error) {
      next({
        name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
        params: { errorMessage: 'An issue occurred while processing your request.' },
      });
    }
  };
}

/**
 * Ensures artifact receipt has been provided from the payment confirmation page,
 * otherwise route to the artifact paid page.
 * If the user is logged in, provide the profile.
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function validateArtifactReceipt(store) {
  /**
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    let profile;
    try {
      profile = await store.dispatch(USER_GET_PROFILE);
    } catch (error) {
      profile = undefined;
    }

    if (!to?.params?.receiptDetails) {
      next({
        name: routeNames.ROUTE_ARTIFACT_ALREADY_PAID,
        params: {
          artifactId: to?.params?.artifactId,
        },
      });
      return;
    }

    try {
      to.params.profile = profile;
      const artifact = await store.dispatch(ARTIFACT_GET, to?.params?.artifactId);
      to.params.artifactDetails = artifact?.artifactDetails;
      next();
    } catch (error) {
      next({
        name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
        params: { errorMessage: 'An issue occurred while processing your request.' },
      });
    }
  };
}

/**
 * Ensures there is a valid payment decision against the artifact.
 * Users MUST be logged in at this point.
 * If there are no approved decisions, route to user not approved page,
 * if an unknown error occurs, route them to a generic page.
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function validatePaymentConfirmation(store) {
  /**
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    const artifactId = to?.params?.artifactId;

    const resp = await Promise.allSettled([store.dispatch(ARTIFACT_GET, artifactId), store.dispatch(USER_GET_PROFILE)]);

    if (!resp.every((result) => result.status === 'fulfilled')) {
      next({ name: routeNames.ROUTE_SOMETHING_WENT_WRONG });
      return;
    }

    try {
      const [artifact, profile] = resp;

      if (isArtifactPaid(artifact?.value)) {
        next({ name: routeNames.ROUTE_ARTIFACT_ALREADY_PAID, params: { artifactId } });
        return;
      }

      const { data } = await bnplApi.getPaymentPlanDecisionsByArtifactId({
        artifactId,
        artifactType: artifact?.value?.artifactDetails?.artifactType,
        partyId: profile?.value?.partyId,
      });

      const paymentOptions = filterPaymentOptionsByDecisions(artifact?.value?.paymentOptions, data);

      if (paymentOptions.length === 0) {
        next({ name: routeNames.ROUTE_USER_NOT_APPROVED, params: { artifactId } });
        return;
      }

      to.params.artifactDetails = artifact?.value?.artifactDetails;
      to.params.paymentOptions = paymentOptions;
      to.params.profile = profile?.value;
      next();
    } catch (error) {
      next({ name: routeNames.ROUTE_SOMETHING_WENT_WRONG });
    }
  };
}
