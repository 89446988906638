<script>
import { computed, defineComponent } from '@vue/composition-api';
import { BASE, sizes } from '@/lib/variants';

const MONO = 'mono';
const REVERSE = 'reverse';
const STANDARD = 'standard';
const allowedVariants = [MONO, REVERSE, STANDARD];

export default defineComponent({
  name: 'Logo',
  props: {
    variant: {
      type: String,
      required: false,
      default: 'standard',
      validator: (value) => allowedVariants.includes(value),
    },
    size: {
      type: String,
      required: false,
      default: BASE,
      validator: (value) => Object.values(sizes()).includes(value),
    },
  },
  setup(props) {
    const isMono = computed(() => props.variant === MONO);
    const isReverse = computed(() => props.variant === REVERSE);

    return {
      isMono,
      isReverse,
    };
  },
});
</script>

<template>
  <img v-if="isMono" :class="['logo', size]" src="@/assets/logo-reverse-mono.svg" alt="brand-logo-light" />
  <img v-else-if="isReverse" :class="['logo', size]" src="@/assets/logo-reverse.svg" alt="brand-logo-light" />
  <img v-else :class="['logo', size]" src="@/assets/logo.svg" alt="brand-logo" />
</template>

<style scoped>
.logo.smaller {
  max-width: 90px;
  min-width: 90px;
}
.logo.small {
  max-width: 120px;
  min-width: 120px;
}
.logo.base {
  max-width: 160px;
  min-width: 160px;
}
.logo.large {
  max-width: 200px;
  min-width: 200px;
}
.logo.larger {
  max-width: 240px;
  min-width: 240px;
}
</style>
