<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import BaseCard from '@/components/base/base-card.vue';
import BaseProgressBar from '@/components/base/base-progress-bar.vue';
import { formatCurrency, formatDate } from '@/lib/formats';

export default defineComponent({
  name: 'DashboardPaymentPlan',
  components: { BaseCard, BaseProgressBar },
  props: {
    paymentPlan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expand = ref(false);
    const renderLinkText = computed(() => (expand.value ? 'less details' : 'more details'));
    const progress = computed(() => {
      const total = props.paymentPlan?.planSchedule.length;
      const paid = props.paymentPlan.planSchedule.filter((item) => item.status === 'Paid').length;
      return (paid / total) * 100;
    });

    const nextPayment = computed(() => {
      const pendingPayments = props.paymentPlan.planSchedule.filter((item) => item.status !== 'Paid');
      if (pendingPayments.length === 0) {
        return {
          amount: '',
          dueDate: '',
        };
      }
      return pendingPayments[0];
    });

    const pendingPayments = computed(() => {
      const pending = props.paymentPlan.planSchedule.filter((item) => item.status !== 'Paid');
      return pending.length;
    });

    function renderHelpText(payment) {
      if (payment.repaymentIndex === 1) {
        return 'First payment - includes setup fee';
      }
      if (payment.repaymentIndex === props?.paymentPlan?.planSchedule?.length) {
        return 'Final payment';
      }
      return '';
    }

    function renderPaymentScheduleRowBg(payment) {
      if (payment.status === 'Paid') {
        return 'bg-shades-lighter';
      }
      return '';
    }

    function toggleScheduleExpand() {
      expand.value = !expand.value;
    }

    return {
      formatCurrency,
      progress,
      nextPayment,
      pendingPayments,
      renderLinkText,
      toggleScheduleExpand,
      formatDate,
      expand,
      renderHelpText,
      renderPaymentScheduleRowBg,
    };
  },
});
</script>

<template>
  <base-card class="flex flex-col min-w-full">
    <div class="flex justify-between">
      <span class="font-bold">{{ paymentPlan.merchantName }}</span>
      <span class="text-1xl font-semibold">{{ formatCurrency(paymentPlan.totalAmount) }}</span>
    </div>
    <div class="flex justify-between pb-2">
      <span class="text-xs text-shades-darker">Ref. {{ paymentPlan.artifactExternalId }}</span>
      <span class="text-xs text-shades-darker"> {{ formatCurrency(paymentPlan.remainingAmount) }} remaining </span>
    </div>
    <base-progress-bar class="pb-2" :progress="progress" />
    <div class="flex justify-between">
      <span class="text-xs"
        >Next: {{ formatCurrency(nextPayment.amount) }} on {{ formatDate(nextPayment.dueDate) }}
      </span>
      <a @click="toggleScheduleExpand" class="text-xs text-link">{{ renderLinkText }}</a>
    </div>
    <div v-show="expand" class="pt-4">
      <hr />
      <p class="pt-4 text-sm font-bold">Plan schedule</p>
      <div
        v-for="payment in paymentPlan.planSchedule"
        :key="payment.repaymentIndex"
        :class="['flex items-center text-xs py-1', renderPaymentScheduleRowBg(payment)]"
      >
        <p>{{ formatDate(payment.dueDate) }}</p>
        <p class="flex-grow text-xs px-3 text-shades-darker">{{ renderHelpText(payment) }}</p>
        <p>{{ formatCurrency(payment.amount) }}</p>
      </div>
    </div>
  </base-card>
</template>

<style scoped></style>
