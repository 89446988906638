export const ROUTE_MERCHANT_DASHBOARD = 'route-merchant-dashboard';
export const ROUTE_PAY = 'route-pay';
export const ROUTE_PAY_PENDING = 'route-pay-pending';
export const ROUTE_PAY_CONFIRMATION = 'route-pay-confirmation';
export const ROUTE_CARD_PAYMENT = 'route-card-payment';
export const ROUTE_USER_NOT_APPROVED = 'route-user-not-approved';
export const ROUTE_ARTIFACT_ALREADY_PAID = 'route-artifact-already-paid';
export const ROUTE_ARTIFACT_RECEIPT = 'route-artifact-receipt';
export const ROUTE_CUSTOMER_DASHBOARD = 'route-customer-dashboard';
export const ROUTE_CUSTOMER_SETTINGS = 'route-customer-settings';
export const ROUTE_REGISTER = 'register';
export const ROUTE_REGISTER_CONFIRM_EMAIL = 'register-confirm-email';
export const ROUTE_REGISTER_VERIFY_EMAIL = 'register-verify-email';
export const ROUTE_REGISTER_VERIFY_PHONE = 'register-verify-phone';
export const ROUTE_REGISTER_LINK_BANK = 'register-link-bank';
export const ROUTE_REGISTER_CUSTOMER_ADDRESS = 'register-customer-address';
export const ROUTE_REGISTER_CUSTOMER_SELECT_ACCOUNT = 'register-customer-select-account';
export const ROUTE_REGISTER_SELECT_PAYMENT = 'register-select-payment';
export const ROUTE_FORGOT_PASSWORD = 'forgot-password';
export const ROUTE_RESET_PASSWORD = 'reset-password';
export const ROUTE_REGISTER_RECEIPT = 'register-receipt';
export const ROUTE_SOMETHING_WENT_WRONG = 'something-went-wrong';
export const ROUTE_LOGIN = 'login';
export const ROUTE_LOGOUT = 'logout';
