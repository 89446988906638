<script>
import { computed, defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';
import { formatCurrency, formatDate } from '@/lib/formats';
import BaseIcon from '@/components/base/base-icon.vue';
import BaseCard from '@/components/base/base-card.vue';
import DashboardOwingSummary from '@/components/dashboard-owing-summary.vue';
import DashboardRepaymentSummary from '@/components/dashboard-repayment-summary.vue';
import NavBar from '@/components/nav-bar.vue';
import DashboardPaymentPlan from '@/components/dashboard-payment-plan.vue';

export default defineComponent({
  name: 'CustomerDashboard',
  props: {
    user: {
      type: Object,
      required: true,
    },
    overview: {
      type: Object,
      required: true,
    },
    repayments: {
      type: Object,
      required: true,
    },
    paymentPlans: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseIcon,
    BaseCard,
    NavBar,
    DashboardPaymentPlan,
    DashboardOwingSummary,
    DashboardRepaymentSummary,
  },
  setup(props) {
    const brand = useBrand();
    const hasActivePlans = computed(() => props?.paymentPlans?.activePlans?.length > 0);
    const hasHistoricalPlans = computed(() => props?.paymentPlans?.history?.length > 0);

    return {
      brand,
      formatCurrency,
      formatDate,
      hasActivePlans,
      hasHistoricalPlans,
    };
  },
});
</script>

<template>
  <div class="px-2">
    <h1 class="text-2xl font-extrabold text-white mb-3">Welcome back, {{ user.firstName }}</h1>
    <h3 class="text-xl font-extrabold text-white mb-3">Overview</h3>
    <div class="flex flex-wrap justify-between">
      <DashboardOwingSummary class="grid-item" :overview="overview" />
      <DashboardRepaymentSummary class="grid-item" :summary="repayments" />
    </div>
    <h3 class="text-xl mt-4 mb-2">Active Plans</h3>
    <p class="text-sm text-shades-darker">
      Keep track of these and ensure there are enough funds in your account on these dates as this is when we will
      auto-debit your account.
    </p>
    <template v-if="hasActivePlans">
      <div class="flex py-5 flex-col">
        <DashboardPaymentPlan
          class="mb-2"
          v-for="payment in paymentPlans.activePlans"
          :key="payment.id"
          :paymentPlan="payment"
        />
      </div>
    </template>
    <template v-else>
      <div class="flex py-5">
        <div class="flex flex-col items-center border rounded-md w-full mx-auto p-4">
          <img class="mb-4" src="@/assets/active-repayments-icon.svg" alt="" />
          <p class="font-bold mb-1">No Active Plans</p>
          <p class="text-xs text-shades-dark">
            When you pay with a {{ brand.name }} plan, you'll be able to view and track your repayments over time
          </p>
        </div>
      </div>
    </template>
    <div class="flex items-center">
      <h3 class="text-xl mr-3">Payment history</h3>
      <template v-if="hasHistoricalPlans">
        <base-icon icon="fa-angle-down" />
      </template>
      <template v-else>
        <span class="text-shades-dark text-sm">No past payment plans</span>
      </template>
    </div>
  </div>
</template>

<style scoped>
.grid-item {
  @apply my-4 flex-grow sm:first:mr-2;
}
</style>
