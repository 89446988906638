<script>
import { defineComponent } from '@vue/composition-api';
import Logo from '@/components/logo.vue';

export default defineComponent({
  components: { Logo },
  name: 'SimpleLayout',
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="flex flex-col max-w-screen-2xl mx-auto px-2">
    <Logo class="p-2" size="small" />
    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped>
</style>
