<script>
import currency from 'currency.js';
import { computed, defineComponent } from '@vue/composition-api';
import { formatCurrency } from '@/lib/formats';
import BaseCard from '@/components/base/base-card.vue';

export default defineComponent({
  name: 'DashboardOwingSummary',
  props: {
    overview: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseCard,
  },
  setup(props) {
    const isMonthZero = computed(() => {
      const month = currency(props?.overview?.month);
      return month.value === 0;
    });

    return {
      isMonthZero,
      formatCurrency,
    };
  },
});
</script>

<template>
  <base-card v-if="!isMonthZero" class="flex-col h-36 w-80">
    <span class="text-shades-dark">Amount owing</span>
    <div class="flex justify-between mt-2">
      <div class="flex flex-col w-1/2 border-r">
        <span class="text-2xl font-bold my-3">{{ formatCurrency(overview.month) }}</span>
        <span class="text-xs">this month</span>
      </div>
      <div class="flex flex-col ml-3 w-1/2">
        <span class="text-2xl font-bold my-3">{{ formatCurrency(overview.total) }}</span>
        <span class="text-xs">total</span>
      </div>
    </div>
  </base-card>

  <base-card v-else class="flex flex-col h-36 w-80">
    <span>Amount owing</span>
    <span class="text-2xl font-bold">{{ formatCurrency('0.00') }}</span>
  </base-card>
</template>

<style scoped>
</style>
