import { getUserProfile } from '@/api/user';
import { hasProfile } from '@/lib/user';

const MUTATION_UPDATE_CURRENT_PROFILE = 'UPDATE_CURRENT_PROFILE';
const MUTATION_INIT_STATE = 'INIT_STATE';

/**
 *
 * @returns {import('@/lib/user').InitState}
 */
function userInitState() {
  return {
    defaultProfileId: '',
    user: {
      partyId: '',
      partyType: '',
      firstName: 'Adam',
      lastName: 'West',
      email: '',
      hasBankAccounts: false,
    },
    currentProfile: {},
  };
}

export const profileMutations = {
  /**
   *
   * @param {import('@/lib/user').InitState} state
   * @param {string} id
   */
  UPDATE_DEFAULT_PROFILE_ID(state, id) {
    state.defaultProfileId = id;
  },
  /**
   *
   * @param {import('@/lib/user').InitState} state
   * @param {import('@/lib/user').UserProfile} user
   */
  UPDATE_PROFILE(state, user) {
    state.user = user;
  },
  /**
   *
   * @param {import('@/lib/user').InitState} state
   * @param {import('@/lib/user').UserProfile} user
   */
  UPDATE_CURRENT_PROFILE(state, user) {
    state.currentProfile = user;
  },
  INIT_STATE() {},
};

export const profileActions = {
  /**
   * user store init state
   * @param {import('vuex').ActionContext} ctx
   */
  async initState({ commit }) {
    commit(MUTATION_INIT_STATE);
    return Promise.resolve();
  },
  /**
   * get user profile and set state
   * @param {import('vuex').ActionContext} ctx store context
   */
  async getCurrentProfile(ctx) {
    try {
      if (!hasProfile(ctx.state)) {
        const { data } = await getUserProfile();
        ctx.commit(MUTATION_UPDATE_CURRENT_PROFILE, data);
      }

      return Promise.resolve(ctx.state.currentProfile);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export const userStore = {
  namespaced: true,
  state: userInitState(),
  mutations: profileMutations,
  actions: profileActions,
};
