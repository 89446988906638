/* eslint-disable import/prefer-default-export */

export const PARTY_TYPE_PERSON = 'Person';

/**
 * @typedef {Object} UserDetails
 * @property {Object} userDetails - user details
 * @property {string} userDetails.partyId - users' party id
 * @property {string} userDetails.partyType - person / partner / org
 * @property {string} userDetails.firstName - user's first name
 * @property {string} userDetails.lastName - user's last name
 * @property {string} userDetails.email user's email
 * @property {string} userDetails.phone user's phone
 * @property {Object} bankDetails - user bank details
 * @property {string} bankDetails.accountName - bank account name
 * @property {string} bankDetails.accountNumber - bank account number
 * @property {string} bankDetails.accountBranch - bank account branch
 */

/**
 * @typedef {Object} UserProfile
 * @property {string} partyId user id
 * @property {string} partyType person / partner / org
 * @property {string} firstName user's first name
 * @property {string} lastName user's last name
 * @property {string} email user's email
 * @property {boolean} hasBankDetails if user has bank details
 * @property {boolean} hasPhoneVerified if user has their phone verified
 */

/**
 * @typedef {Object} InitState
 * @property {string} defaultProfileId is the user just registered
 * @property {UserProfile} user user's profile
 * @property {UserProfile} currentProfile active profile
 */

/**
 *
 * @param {InitState} state
 * @returns
 */
export function hasProfile(state) {
  return state?.currentProfile?.partyId !== undefined;
}
