/* eslint-disable no-param-reassign */
import CustomerDashboard from '@/views/customer-dashboard.vue';
import { customerDashboard, getCustomerDetails } from '@/router/middleware-dashboard';

import * as routeNames from '@/lib/router';
import { LAYOUT_CUSTOMER_DASHBOARD } from '@/lib/layouts';

import store from '@/store';

const customerRoutes = [
  {
    path: '/',
    name: routeNames.ROUTE_CUSTOMER_DASHBOARD,
    meta: {
      requiresAuth: true,
      layout: LAYOUT_CUSTOMER_DASHBOARD,
    },
    props: true,
    beforeEnter: customerDashboard(store),
    component: CustomerDashboard,
  },
  {
    path: '/settings',
    name: routeNames.ROUTE_CUSTOMER_SETTINGS,
    meta: {
      requiresAuth: true,
      layout: LAYOUT_CUSTOMER_DASHBOARD,
    },
    props: true,
    beforeEnter: getCustomerDetails,
    component: () => import(/* webpackChunkName: "customer-dashboard" */ '@/views/customer-settings.vue'),
  },
];

export default customerRoutes;
