import { ARTIFACT_INIT_STATE } from '@/lib/store';

const ARTIFACT_UPDATE_ARTIFACT = 'artifact/UPDATE_ARTIFACT';
const ARTIFACT_LAST_UPDATE_ARTIFACT_ID = 'artifact/UPDATE_LAST_ARTIFACT_ID';
const STORE_LAST_ARTIFACT_ID = '--last-artifact-id';

/**
 * subscribe to artifact init state and update artifact events.
 * Events:
 *
 * On artifact init state, check if local storage has the artifact,
 * trigger an artifact fetch.
 *
 * On artifact update, store the artifact id in local storage in case user losses the current session.
 * NOTE this is a one shot, once we successfully fetch the artifact id, we remove any reference to it.
 * @param {Storage} local - local storage
 */
export default function createArtifactSync(local) {
  /**
   * @param {import('node_modules/vuex/types/index').Store} store
   */
  return function artifactIdSync(store) {
    store.subscribe((mutation, state) => {
      if (mutation.type === ARTIFACT_UPDATE_ARTIFACT) {
        const lastArtifact = state?.artifact?.lastArtifactId;
        const newArtifact = mutation?.payload?.artifactDetails?.artifactId;
        if (lastArtifact === newArtifact) {
          return;
        }

        local.setItem(STORE_LAST_ARTIFACT_ID, mutation?.payload?.artifactDetails?.artifactId);
        return;
      }

      if (mutation.type === ARTIFACT_INIT_STATE) {
        const lastArtifactId = local.getItem(STORE_LAST_ARTIFACT_ID);
        if (!lastArtifactId) {
          return;
        }
        store.commit(ARTIFACT_LAST_UPDATE_ARTIFACT_ID, lastArtifactId);
        local.removeItem(STORE_LAST_ARTIFACT_ID);
      }
    });
  };
}
