import { DEFAULT_REGION } from '@/lib/time';

const DEFAULT_CURRENCY = process.env.VUE_APP_CURRENCY || 'AUD';

const currencyFormatter = new Intl.NumberFormat(DEFAULT_REGION, {
  style: 'currency',
  currency: DEFAULT_CURRENCY,
  minimumFractionDigits: 2,
});
export const formatCurrency = (value) => currencyFormatter.format(value);

/**
 * Takes in a Date object and formats date for human readable
 * eg dd mmm yyy eg 19 Jan 2000
 * @param {Date} date Date object
 * @returns
 */
export function formatDate(date) {
  const parsedDate = new Date(date);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    localeMatcher: 'lookup',
  };
  if (Number.isNaN(Date.parse(parsedDate))) {
    return '';
  }
  return parsedDate.toLocaleDateString(DEFAULT_REGION, options);
}

/**
 * returns the firm legal identifier type based on region:
 * AU : ABN,
 * US: EID
 * @returns
 */
export function formatFirmLegalIdentifierType() {
  const identifiers = {
    'en-AU': 'ABN',
    'en-US': 'EID',
  };
  return identifiers[DEFAULT_REGION];
}
