/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { LAYOUT_LOGIN, LAYOUT_REGISTRATION } from '@/lib/layouts';
import { registrationCustomerValidateEmail, regoLinkBank, regoSelectAccount } from '@/router/middleware-registration';
import { getCustomerProfileOrRouteTo, getArtifactDetailsIfExists } from '@/router/middleware';
import * as routeNames from '@/lib/router';
import store from '@/store';

const registrationRoutes = [
  {
    path: '/registration',
    name: routeNames.ROUTE_REGISTER,
    meta: {
      layout: LAYOUT_LOGIN,
    },
    beforeEnter: getArtifactDetailsIfExists(store),
    props: true,
    component: () => import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-create-account.vue'),
  },
  {
    path: '/registration/confirm-email',
    meta: {
      layout: LAYOUT_LOGIN,
    },
    beforeEnter: getArtifactDetailsIfExists(store),
    props: true,
    name: routeNames.ROUTE_REGISTER_CONFIRM_EMAIL,
    component: () => import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-email-confirm.vue'),
  },
  {
    path: '/registration/verify-email/:token',
    meta: {
      layout: LAYOUT_REGISTRATION,
    },
    props: true,
    name: routeNames.ROUTE_REGISTER_VERIFY_EMAIL,
    beforeEnter: registrationCustomerValidateEmail(store),
    component: () =>
      import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-email-verification.vue'),
  },
  {
    path: '/registration/link-bank',
    meta: {
      requiresAuth: true,
      layout: LAYOUT_REGISTRATION,
    },
    props: true,
    beforeEnter: regoLinkBank(store),
    name: routeNames.ROUTE_REGISTER_LINK_BANK,
    component: () => import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-link-bank.vue'),
  },
  {
    path: '/registration/select-account',
    meta: {
      requiresAuth: true,
      layout: LAYOUT_REGISTRATION,
    },
    props: true,
    beforeEnter: regoSelectAccount(store),
    name: routeNames.ROUTE_REGISTER_CUSTOMER_SELECT_ACCOUNT,
    component: () => import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-select-account.vue'),
  },
  {
    path: '/registration/customer-address',
    meta: {
      requiresAuth: true,
      layout: LAYOUT_REGISTRATION,
    },
    props: true,
    name: routeNames.ROUTE_REGISTER_CUSTOMER_ADDRESS,
    beforeEnter: getCustomerProfileOrRouteTo(store),
    component: () =>
      import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-residential-address.vue'),
  },
  {
    path: '/registration/verify-phone',
    meta: {
      requiresAuth: true,
      layout: LAYOUT_REGISTRATION,
    },
    name: routeNames.ROUTE_REGISTER_VERIFY_PHONE,
    component: () =>
      import(/* webpackChunkName: "registration-customer" */ '@/views/rego-customer-confirm-phone-number.vue'),
  },
  {
    path: '/registration/*',
    redirect: { name: routeNames.ROUTE_REGISTER },
  },
];

export default registrationRoutes;
