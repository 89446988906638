export const PRIMARY = 'primary';
export const ACCENT = 'accent';
export const SHADES = 'shades';
export const DARK = 'dark';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';

// core app variants
export function variants() {
  return {
    PRIMARY,
    ACCENT,
    SHADES,
    DARK,
    SUCCESS,
    WARNING,
    DANGER,
  };
}

export const SMALLER = 'smaller';
export const SMALL = 'small';
export const BASE = 'base';
export const LARGE = 'large';
export const LARGER = 'larger';

// core app sizes
export function sizes() {
  return {
    SMALLER,
    SMALL,
    BASE,
    LARGE,
    LARGER,
  };
}

export const TOP = 'top';
export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const BOTTOM = 'bottom';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_RIGHT = 'bottom-right';

// core app positions
export function positions() {
  return {
    TOP,
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
  };
}
