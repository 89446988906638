/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */

/**
 * middleware for route: dashboard
 * @param {Storage} local localStorage object
 * @returns
 */
export function dashboard(local) {
  return function beforeEnter(to, from, next) {
    let foo;
    if (local.getItem('foo')) {
      foo = local.getItem('foo');
    }
    to.params.foo = foo;
    next();
  };
}
