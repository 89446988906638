/* eslint-disable no-param-reassign */
const brand = {
  parentOrg: process.env.VUE_APP_BRAND_PARENT_ORG,
  name: process.env.VUE_APP_BRAND_NAME,
  contactEmail: process.env.VUE_APP_BRAND_CONTACT_EMAIL,
  contactPhone: process.env.VUE_APP_BRAND_PHONE,
  customerTerms: process.env.VUE_APP_BRAND_TERMS,
  merchantTerms: process.env.VUE_APP_BRAND_MERCHANT_TERMS,
  about: process.env.VUE_APP_BRAND_ABOUT,
  faqs: process.env.VUE_APP_BRAND_FAQS,
  privacy: process.env.VUE_APP_BRAND_PRIVACY,
  openApiProvider: process.env.VUE_APP_BRAND_OPEN_API,
  openApiProviderUrl: process.env.VUE_APP_BRAND_OPEN_API_WEBSITE,
};

export function useBrand() {
  return { ...brand };
}

export default {
  install: (app) => {
    app.prototype.$brand = { ...brand };
  },
};
