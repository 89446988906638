<script>
import Vue from 'vue';
import { computed, defineComponent, onBeforeMount, provide } from '@vue/composition-api';
import Hotjar from 'vue-hotjar';
import store from '@/store';
import router from '@/router';
import { INJECTION_STORE_KEY } from '@/use/store';
import { INJECTION_ROUTER_KEY } from '@/use/router';
import { LAYOUT_LOADING } from '@/lib/layouts';
import { USER_GET_PROFILE, USER_INIT_STATE } from '@/lib/store';

Vue.use(Hotjar, {
  id: '2870581',
  isProduction: process.env.NODE_ENV === 'production',
});

export default defineComponent({
  setup() {
    provide(INJECTION_STORE_KEY, store);
    provide(INJECTION_ROUTER_KEY, router);

    onBeforeMount(async () => {
      await Promise.allSettled([store.dispatch(USER_INIT_STATE), store.dispatch(USER_GET_PROFILE)]);
    });

    const profile = computed(() => store?.state?.user?.currentProfile);

    return {
      profile,
      LAYOUT_LOADING,
    };
  },
});
</script>

<template>
  <div id="app">
    <keep-alive>
      <component :user="profile" :is="$route.meta.layout || LAYOUT_LOADING">
        <router-view />
      </component>
    </keep-alive>
  </div>
</template>

<style></style>
