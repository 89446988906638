/* eslint-disable import/prefer-default-export */
import { apiGet } from '@/lib/api';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/v3/users`;

/**
 * Get a user's profile
 * @returns {Promise<import('axios').AxiosResponse<import('@/lib/user').UserProfile>>}
 */
export function getUserProfile() {
  return apiGet(`${baseUrl}/profile`);
}

/**
 * get user details
 * @returns {Promise<import('axios').AxiosResponse<import('@/lib/user').UserDetails>>}
 */
export function getUserDetails() {
  return apiGet(`${baseUrl}/details`);
}

const plansUrl = `${process.env.VUE_APP_API_URL}/api/v3/plans/active`;

/**
 * Get Payment plans by party id
 * @param {String} id party id
 * @returns
 */
export function getPaymentPlans() {
  return apiGet(plansUrl);
}
