<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseIcon',
  props: {
    pack: {
      type: String,
      required: false,
      default: 'fas',
      validator: (value) => ['fas', 'far'].includes(value),
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {},
});
</script>

<template>
  <i :class="[pack, icon, size]"></i>
</template>

<style scoped></style>
