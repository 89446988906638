/* eslint-disable import/prefer-default-export */
import { apiDelete, apiGet, apiPost } from '@/lib/api';

const customerBaseUrl = `${process.env.VUE_APP_API_URL}/api/v3/registration/customer`;

/**
 * Post create new customer account
 * @param {import('@/lib/registration').CustomerAccount} payload customer account
 * @returns
 */
function postCustomerAccount(payload) {
  return apiPost(customerBaseUrl, payload);
}

/**
 * Post verify customer email token
 * @param {string} token jwt token
 * @returns {Promise<any>}
 */
function postVerifyEmail(token = '') {
  const url = `${customerBaseUrl}/verify-email/${token}`;
  return apiPost(url);
}
/**
 * Verify phone
 * @param {Object} payload
 * @param {string} payload.phone
 * @returns { Promise < AxiosResponse < any >>}
 */
function postRequestOtp(payload) {
  const url = `${customerBaseUrl}/verify-phone/otp`;
  return apiPost(url, payload);
}

/**
 * Verify customer's phone by sending an SMS to the customer
 * @param {Object} payload
 * @param {string} payload.otp- customer's phone number
 * * @returns { Promise < AxiosResponse < any >>}
 */
function postVerifyOtp(payload) {
  const url = `${customerBaseUrl}/verify-phone`;
  return apiPost(url, payload);
}

/**
 * Create a user residential history
 * @param {object} payload - create account form
 * @param {string} payload.currentAddress - user's current address name
 * @param {string} payload.currentAddressMoveInDate - user current address move in date
 * @param {string} payload.previousAddress - previous address if any
 * @param {string} payload.previousAddressMoveInDate - move in date
 * @param {string?} payload.previousAddressMoveOutDate - move out date
 * @returns { Promise <AxiosResponse<any>>}
 */
function postCustomerDetails(payload) {
  const url = `${customerBaseUrl}/residential-address`;
  return apiPost(url, payload);
}
/**
 * Verify identity with licence OR passport
 * @param {object} payload - id verification details form
 * @param {string} payload.idType - id type (passport OR licence)
 * @param {string?} payload.stateOfIssue - user's current address name
 * @param {string?} payload.countryOfIssue - user current address move in date
 * @param {string} payload.reference - licence or passport number
 * @param {string?} payload.dateOfIssue - date of issue (passport only)
 * @param {string} payload.dateOfExpiry - date of expiry (passport AND licence)
 * @param {string?} payload.cardId - applicable to NSW licence only
 * @returns { Promise <AxiosResponse<any>>}
 */
function postCustomerIdentification(payload) {
  const url = `${customerBaseUrl}/customer-identification`;
  return apiPost(url, payload);
}

/**
 *  * Get list of banks the customer is able to link to
 * @returns
 */
function getBankList() {
  const url = `${customerBaseUrl}/link-bank`;
  return apiGet(url);
}

/**
 * Link customer's bank account
 * @param {object} bankForm bank link form
 * @param {string} bankForm.institutionId id of the institution to link
 * @param {string} bankForm.username customer username
 * @param {string} bankForm.password customer password
 * @param {string?} bankForm.secondaryLogin optional secondary login if required by institution
 * @param {string?} bankForm.securityCode optional security if required by institution
 * @returns
 */
function postLinkBank(bankForm) {
  const url = `${customerBaseUrl}/link-bank`;
  return apiPost(url, bankForm);
}

/**
 * Delete a bank link for a user
 * @returns
 */
function deleteBankLink() {
  const url = `${customerBaseUrl}/link-bank`;
  return apiDelete(url);
}

/**
 * Get bank link synchronization status
 * @returns
 */
function getLinkBankStatus() {
  const url = `${customerBaseUrl}/link-bank/status`;
  return apiGet(url);
}

/**
 * Get user's account from linked bank
 * @returns
 */
function getAccounts() {
  const url = `${customerBaseUrl}/bank-accounts`;
  return apiGet(url);
}

/**
 * create account for the user to be direct debited
 * @param {String} token account token
 * @returns
 */
function postSelectAccount(token) {
  const url = `${customerBaseUrl}/bank-accounts`;
  return apiPost(url, { token });
}

export const registerCustomerApi = {
  postCustomerAccount,
  postVerifyEmail,
  postCustomerDetails,
  postCustomerIdentification,
  postRequestOtp,
  postVerifyOtp,
  postLinkBank,
  getLinkBankStatus,
  getBankList,
  getAccounts,
  postSelectAccount,
  deleteBankLink,
};
