<script>
import { defineComponent } from '@vue/composition-api';
import { variants } from '@/lib/variants';

export default defineComponent({
  name: 'BaseProgressBar',
  props: {
    variant: {
      required: false,
      type: String,
      default: variants().PRIMARY,
      validator: (value) => Object.values(variants()).includes(value),
    },
    progress: {
      type: Number,
      required: false,
      default: 50,
      validator: (value) => value >= 0 && value <= 100,
    },
  },
  setup() {},
});
</script>

<template>
  <div class="">
    <div :class="['overflow-hidden text-xs flex bg-shades-lighter h-3 rounded-lg', variant]">
      <div
        :style="{ width: `${progress}%` }"
        :class="[
          'shadow-none flex flex-col text-center whitespace-nowrap justify-center progress progress-bar',
          variant,
        ]"
      />
    </div>
  </div>
</template>

<style scoped>
.progress-bar.primary {
  @apply bg-primary;
}

.progress-bar.shades {
  @apply bg-shades;
}

.progress-bar.dark {
  @apply bg-dark;
}

.progress-bar.accent {
  @apply bg-accent;
}

.progress-bar.success {
  @apply bg-success;
}

.progress-bar.warning {
  @apply bg-warning;
}

.progress-bar.danger {
  @apply bg-danger;
}
</style>
