/* eslint-disable no-param-reassign */
import {
  handlePaidArtifact,
  validateCardPaymentRequest,
  validateArtifactReceipt,
  validatePaymentConfirmation,
  validatePaymentRequest,
} from '@/router/middleware-pay';

import * as routeNames from '@/lib/router';
import { LAYOUT_SIMPLE, LAYOUT_PAYMENT_REQUEST } from '@/lib/layouts';
import store from '@/store';

const payRoutes = [
  {
    path: '/pay/:artifactId/pending',
    name: routeNames.ROUTE_PAY_PENDING,
    meta: {
      layout: LAYOUT_SIMPLE,
    },
    component: () => import(/* webpackChunkName: "pay" */ '@/views/request-pending.vue'),
  },
  {
    path: '/pay/:artifactId/confirm',
    name: routeNames.ROUTE_PAY_CONFIRMATION,
    meta: {
      layout: LAYOUT_SIMPLE,
    },
    props: true,
    beforeEnter: validatePaymentConfirmation(store),
    component: () => import(/* webpackChunkName: "pay" */ '@/views/payment-confirmation.vue'),
  },
  {
    path: '/pay/:artifactId/card-payment',
    name: routeNames.ROUTE_CARD_PAYMENT,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    beforeEnter: validateCardPaymentRequest(store),
    component: () => import(/* webpackChunkName: "pay" */ '@/views/card-payment.vue'),
  },
  {
    path: '/pay/:artifactId/receipt',
    name: routeNames.ROUTE_ARTIFACT_RECEIPT,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    beforeEnter: validateArtifactReceipt(store),
    component: () => import(/* webpackChunkName: "pay" */ '@/views/payment-receipt.vue'),
  },
  {
    path: '/pay/:artifactId/not-approved',
    name: routeNames.ROUTE_USER_NOT_APPROVED,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    component: () => import(/* webpackChunkName:  "pay" */ '@/views/user-not-approved.vue'),
  },
  {
    path: '/pay/:artifactId/already-paid',
    name: routeNames.ROUTE_ARTIFACT_ALREADY_PAID,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    beforeEnter: handlePaidArtifact,
    component: () => import(/* webpackChunkName:  "pay" */ '@/views/artifact-already-paid.vue'),
  },
  {
    path: '/pay/:artifactId',
    name: routeNames.ROUTE_PAY,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    beforeEnter: validatePaymentRequest(store),
    component: () => import(/* webpackChunkName: "pay" */ '@/views/payment-request.vue'),
  },
];

export default payRoutes;
