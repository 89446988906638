import Vue from 'vue';
import Vuex from 'vuex';
import syncProfile from '@/store/plugins/sync-profile';
import syncArtifact from '@/store/plugins/sync-artifact';

// inject-imports
import { artifactStore } from '@/store/artifact';
import { userStore } from '@/store/user';
import { ARTIFACT_INIT_STATE } from '@/lib/store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    // inject-modules
    artifact: artifactStore,
    user: userStore,
  },
  plugins: [syncProfile(localStorage), syncArtifact(localStorage)],
});

store.commit(ARTIFACT_INIT_STATE);

export default store;
