<script>
import Loader from '@/components/loader.vue';

export default {
  name: 'LoadingLayout',
  components: {
    Loader,
  },
};
</script>

<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <Loader />
  </div>
</template>
