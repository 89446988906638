/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { getPaymentPlans, getUserDetails } from '@/api/user';
import { ROUTE_LOGIN } from '@/lib/router';
import { USER_GET_PROFILE } from '@/lib/store';

/**
 * get all the data required to render the customer dashboard
 * @param {import('vuex').Store} store vuex store
 */
export function customerDashboard(store) {
  /**
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    try {
      const profile = await store.dispatch(USER_GET_PROFILE);
      const { data } = await getPaymentPlans();
      to.params.user = profile;
      // TODO - replace this with actual api calls once they are available
      to.params.overview = {};
      to.params.repayments = {};
      to.params.paymentPlans = data;
      next();
    } catch (error) {
      next({ name: ROUTE_LOGIN });
    }
  };
}

/**
 * get the user personal and bank details and attach them to props: userDetails, bankDetails.
 * If the user is not authorised, route them to login.
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @param {import('vue-router').NavigationGuardNext} next
 * @returns
 */
export async function getCustomerDetails(to, from, next) {
  try {
    const { data } = await getUserDetails();
    to.params.userDetails = data?.userDetails;
    next();
  } catch (error) {
    next({ name: ROUTE_LOGIN });
  }
}
