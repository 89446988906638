<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseCard',
  setup() {},
});
</script>

<template>
  <div class="rounded-lg bg-white shadow-md min-h-32 w-64 p-4">
    <slot />
  </div>
</template>

<style scoped></style>
