/* eslint-disable no-param-reassign */

import { getArtifactDetails } from '@/api/artifacts';

const MUTATION_UPDATE_ARTIFACT = 'UPDATE_ARTIFACT';

/**
 * @typedef ArtifactState
 * @property {String} lastArtifactId last artifact id
 * @property {import('@/lib/artifacts').Artifact} artifact
 * @returns
 */

/**
 * init state
 * @returns {ArtifactState}
 */
export function artifactInitState() {
  return {
    lastArtifactId: undefined,
    artifact: {
      artifactDetails: undefined,
      paymentOptions: undefined,
      receiptDetails: undefined,
    },
  };
}

export const artifactMutations = {
  /**
   * Update state with latest artifact
   * @param {ArtifactState} state vuex state
   * @param {import('@/lib/artifacts').Artifact} artifact artifact
   */
  UPDATE_ARTIFACT(state, artifact) {
    state.artifact = artifact;
  },
  /**
   * Update state with latest artifact
   * @param {ArtifactState} state vuex state
   * @param {String} id artifact id
   */
  UPDATE_LAST_ARTIFACT_ID(state, id) {
    state.lastArtifactId = id;
  },
  // used to trigger artifact sync events
  initState() {},
};

export const artifactActions = {
  /**
   * gets artifact by id,
   * however if the state has been updated since the api call,
   * do not update state.
   * @param {import('vuex').ActionContext<ArtifactState>} ctx
   * @param {string} id artifact id
   * @returns {Promise<import('@/lib/artifacts').Artifact>}
   */
  async getLastArtifact(ctx) {
    try {
      if (!ctx.state.lastArtifactId) {
        return Promise.resolve(ctx?.state?.artifact);
      }
      const { data } = await getArtifactDetails(ctx?.state?.lastArtifactId);
      if (!ctx.state?.artifact?.artifactDetails?.artifactId) {
        ctx.commit(MUTATION_UPDATE_ARTIFACT, data);
      }
      return Promise.resolve(ctx?.state?.artifact);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * get artifact by id.
   * @param {import('vuex').ActionContext<ArtifactState>} ctx
   * @param {string} id artifact id
   * @returns {Promise<import('@/lib/artifacts').Artifact>}
   */
  async getArtifact(ctx, id) {
    try {
      if (ctx?.state?.artifact?.artifactDetails?.artifactId === id) {
        return ctx.state.artifact;
      }
      const { data } = await getArtifactDetails(id);
      ctx.commit(MUTATION_UPDATE_ARTIFACT, data);
      return Promise.resolve(ctx?.state?.artifact);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * get update artifact by id and override artifact state
   * @param {import('vuex').ActionContext<ArtifactState>} ctx
   * @param {string} id artifact id
   * @returns {Promise<import('@/lib/artifacts').Artifact>}
   */
  async getArtifactForce(ctx, id) {
    try {
      const { data } = await getArtifactDetails(id);
      ctx.commit(MUTATION_UPDATE_ARTIFACT, data);
      return Promise.resolve(ctx?.state?.artifact);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export const artifactStore = {
  namespaced: true,
  state: artifactInitState(),
  mutations: artifactMutations,
  actions: artifactActions,
};
