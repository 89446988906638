import isBefore from 'date-fns/isBefore';

const millisecondsPerDay = 86400000;
const REGION_AU = 'en-AU';
const REGION_US = 'en-US';
export const DEFAULT_REGION = process.env.VUE_APP_LANGUAGE || REGION_AU;

/**
 * parse string into a valid date format.
 * can handle:
 *  - DD/MM/YYYY
 *  - MM/DD/YYYY
 * @param {String} value date in string form
 * @return {Date} date object
 */
export function parseToDate(value) {
  const dateParts = value.split('/');
  let day = '';
  let month = '';
  let year = '';
  if (DEFAULT_REGION === REGION_US) {
    [month, day, year] = dateParts;
  }
  [day, month, year] = dateParts;
  return new Date(`${year}-${month}-${day}`);
}

/**
 * returns date with the timezone offset added.
 * Date object still represents UTC, helper function when dealing with dates
 * @param {Date} date date object
 */
export function dateToUTCWithOffset(date = new Date()) {
  const minutes = date.getMinutes() - date.getTimezoneOffset();
  const newDate = new Date(date);
  newDate.setMinutes(minutes);
  return newDate;
}

/**
 * returns the number of days between two dates
 * @param {Date} startDate date object
 * @param {Date} endDate date object
 */
export function daysBetween(startDate = new Date(), endDate = new Date()) {
  return Math.trunc((dateToUTCWithOffset(endDate) - dateToUTCWithOffset(startDate)) / millisecondsPerDay);
}

/**
 * Checks if date is in the past
 * @param {String} value - Date string
 */
export function isDateInPast(sourceDate, comparisonDate = new Date()) {
  const date = new Date(sourceDate);
  const compDate = new Date(comparisonDate);

  return isBefore(date, compDate);
}

/**
 * Takes in a date object and adds the local time zone offset,
 * then converts it to string. eg: YYYY-MM-DD.
 * If the date is not valid, return empty string
 * @param {Date} date date to convert
 * @returns {string}
 */
export function dateToString(date) {
  try {
    return dateToUTCWithOffset(date).toISOString().split('T')[0];
  } catch (error) {
    return '';
  }
}
