const PRODUCT_CARD = 'Card';

/**
 * @typedef {Object} ArtifactDetails payment artifact
 * @property {String} artifactId artifact id
 * @property {String} artifactType artifact type (invoice / etc)
 * @property {String} description description of artifact
 * @property {String} amount artifact amount
 * @property {String} externalId customer reference number
 * @property {String} status artifact status
 * @property {String} partyName merchant supplying artifact
 * @property {String} partyLogo merchant logo
 * @property {String} contactNumber merchant contact phone
 * @property {String} contactEmail merchant contact email
 */

/**
 * Payment options
 * @typedef {Object} PaymentOption
 * @property {string} productType - type of product (card, 10 weeks, 6 months, 12 months)
 * @property {string} productFriendly - product description
 * @property {string} loanAmount - artifact amount
 * @property {string} totalAmount - amount including fees
 * @property {Number} repaymentCount - fortnightly repayments
 * @property {string} establishmentFee - fee to setup payment option
 * @property {PaymentOptionSchedule[]} schedule - repayment schedule
 * @returns
 */

/**
 * @typedef {Object} Artifact payment artifact details
 * @property {ArtifactDetails} artifactDetails artifact details
 * @property {PaymentOption[]} paymentOptions artifact payment options
 * @property {boolean} receiptDetails artifact receipt details
 */

/**
 * @typedef {Object} PaymentPlanDecision
 * @property {string} artifactId artifact id
 * @property {string} customerId party id
 * @property {string} customerType customer type
 * @property {string} amount plan amount
 * @property {string} productType product type
 * @property {string} repaymentCount repayment count
 * @property {string} reason rejection reason
 * @property {string} expiresAt time to live on decision
 * @property {boolean?} willLend will lend
 */

/**
 *
 * @param {PaymentOption[]} paymentOptions - merchant payment options
 * @param {PaymentPlanDecision[]} decisions - Payment Plan decisions
 * @returns
 */
export function filterPaymentOptionsByDecisions(paymentOptions, decisions) {
  if (!Array.isArray(decisions) || !Array.isArray(paymentOptions)) {
    throw Error('decisions must be an array, payment options must be an array');
  }
  return paymentOptions.filter((option) =>
    decisions.find((decision) => decision?.productType === option?.productType && decision?.willLend),
  );
}

/**
 * checks to see if payment options includes bnpl
 * @param {PaymentOption[]} paymentOptions all payment options provided by merchant
 */
export function hasBNPL(paymentOptions) {
  if (!paymentOptions?.length) {
    return false;
  }

  const bnpl = paymentOptions.filter((opt) => opt.productType !== PRODUCT_CARD);
  return bnpl.length > 0;
}

/**
 * Checks if the artifact has been paid.
 * Currently the artifact object is not complete and the contract will change,
 * this function is to help handle changing of contract.
 * @param {Artifact} artifact artifact
 */
export function isArtifactPaid(artifact) {
  // can be undefined
  return artifact?.receiptDetails === true;
}
