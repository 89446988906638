import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export const TOKEN_KEY = '--token';
export const STATUS_OK = 200;
export const STATUS_NO_CONTENT = 204;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_FORBIDDEN = 403;
export const STATUS_NOT_FOUND = 404;
export const STATUS_CONFLICT = 409;
export const STATUS_INTERNAL_SERVER_ERROR = 500;

// Backend error types
export const ERROR_BAD_CREDS = 'BAD_CREDENTIALS';
export const ERROR_REQUEST_TIMEOUT = 'REQUEST_TIMEOUT';
export const ERROR_ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED';
export const ERROR_ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED';

/**
 * hacky white list check
 * @param {String} url - url string
 */
export function isUrlWhiteListed(url) {
  try {
    const parsedUrl = new URL(url);
    const list = process.env.VUE_APP_REDIRECT_WHITELIST.split(',');
    return list.includes(parsedUrl.origin);
  } catch (error) {
    return false;
  }
}

function isObject(value) {
  return typeof value === 'object' && value instanceof Object;
}

/**
 * Utility function to convert object properties into snake case
 * @param {Object} data Object or Array
 * @returns object with properties converted into snake case
 */
export function transformSnakeCase(data) {
  if (isObject(data) || Array.isArray(data)) {
    return snakecaseKeys(data, { deep: true });
  }
  if (typeof data === 'string') {
    try {
      const parsedString = JSON.parse(data);
      const snakeCase = snakecaseKeys(parsedString, { deep: true });
      return JSON.stringify(snakeCase);
    } catch (error) {
      // It's not valid JSON, don't do anything with it
      return data;
    }
  }
  return data;
}

/**
 * Utility function to convert object properties into camel case
 * @param {Object} data Object or Array
 * @returns object with properties converted into camel case
 */
export function transformCamelCase(data) {
  if (data) {
    return camelcaseKeys(data, { deep: true });
  }
  return data;
}

/**
 * Wraps standard axios GET request with  transformations
 * @param {String} url url
 * @param {Object} headers
 */
export function apiGet(url, headers = {}) {
  return axios.get(url, {
    headers: {
      ...headers,
    },
    withCredentials: true,
    transformRequest: [...axios.defaults.transformRequest, transformSnakeCase],
    transformResponse: [...axios.defaults.transformResponse, transformCamelCase],
  });
}

/**
 * Wraps standard axios POST request with  transformations
 * @param {String} url url
 * @param {Object|Array} data request body
 * @param {Object} headers
 */
export function apiPost(url = '', data = {}, headers = {}) {
  return axios.post(url, data, {
    headers: {
      ...headers,
    },
    withCredentials: true,
    transformRequest: [...axios.defaults.transformRequest, transformSnakeCase],
    transformResponse: [...axios.defaults.transformResponse, transformCamelCase],
  });
}

/**
 * Wraps standard axios PUT request with  transformation
 * @param {String} url
 * @param {*} data
 * @param {Object} headers
 */
export function apiPut(url = '', data = {}, headers = {}) {
  return axios.put(url, data, {
    headers,
    withCredentials: true,
    transformRequest: [...axios.defaults.transformRequest, transformSnakeCase],
    transformResponse: [...axios.defaults.transformResponse, transformCamelCase],
  });
}

/**
 * Wraps standard axios PATCH request with  transformation
 * @param {String} url
 * @param {*} data
 * @param {Object} headers
 */
export function apiPatch(url = '', data = {}, headers = {}) {
  return axios.patch(url, data, {
    headers,
    withCredentials: true,
    transformRequest: [...axios.defaults.transformRequest, transformSnakeCase],
    transformResponse: [...axios.defaults.transformResponse, transformCamelCase],
  });
}

/**
 * Wraps standard axios DELETE request with  transformation
 * @param {String} url
 * @param {Object} headers
 */
export function apiDelete(url = '', headers = {}) {
  return axios.delete(url, {
    headers,
    withCredentials: true,
    transformRequest: [...axios.defaults.transformRequest, transformSnakeCase],
    transformResponse: [...axios.defaults.transformResponse, transformCamelCase],
  });
}
