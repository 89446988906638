const focusableElements = `a[href]:not([tabindex="-1"]),
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
object,
embed,
*[tabindex]:not([tabindex="-1"]),
*[contenteditable]`;

const KEY_TAB = 'Tab';
const EVENT_KEYDOWN = 'keydown';
const focusTrapInstances = [];

/**
 * add event listeners to element
 * @param {HTMLElement} el - element
 * @param {Function} handler
 */
function addListeners(el, handler) {
  focusTrapInstances.push({ el, handler, event: EVENT_KEYDOWN });
  el.addEventListener(EVENT_KEYDOWN, handler);
}

/**
 * remove event listeners
 * @param {HTMLElement} el - element
 */
function removeListeners(el) {
  const instances = focusTrapInstances.filter((i) => i.el === el);
  instances.forEach((item) => {
    el.removeEventListener(item.event, item.handler);
    const idx = focusTrapInstances.findIndex((i) => i.el === el);
    focusTrapInstances.splice(idx, 1);
  });
}

/**
 * find all focusable elements within the target
 * @param {HTMLElement} element
 * @returns
 */
function findElements(element) {
  if (!element) {
    return null;
  }
  return element.querySelectorAll(focusableElements);
}

/**
 * is the element in focus
 * @param {HTMLElement} el
 * @param {KeyboardEvent} event
 */
function isCurrentFocus(el, event) {
  return event.target === el && event.shiftKey && event.key === KEY_TAB;
}

/**
 *
 * @param {HTMLElement} el - html element
 * @param {Object} binding - binding value
 * @returns
 */
function bindDirective(el, { value = true }) {
  if (!value) {
    return;
  }
  let elementsToFocus = findElements(el);

  if (!elementsToFocus.length) {
    return;
  }

  /**
   * creates a focus trap for an element container
   * @param {KeyboardEvent} event - keyboard event
   */
  function focusTrap(event) {
    elementsToFocus = findElements(el);
    const first = elementsToFocus[0];
    const last = elementsToFocus[elementsToFocus.length - 1];

    if (isCurrentFocus(first, event)) {
      event.preventDefault();
      last.focus();
    } else if (event.target === last) {
      event.preventDefault();
      first.focus();
    }
  }
  addListeners(el, focusTrap);
}

export const DIRECTIVE_FOCUS_TRAP = 'focus-trap';

export default {
  bind(el, binding) {
    bindDirective(el, binding);
  },

  update(el, binding) {
    removeListeners(el);
    bindDirective(el, binding);
  },

  unbind(el) {
    removeListeners(el);
  },
};
