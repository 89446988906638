/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import * as routeNames from '@/lib/router';
import { registerCustomerApi } from '@/api/registration';
import { STATUS_NOT_FOUND } from '@/lib/api';
import { USER_GET_PROFILE, ARTIFACT_GET_LAST_ARTIFACT } from '@/lib/store';
import { isArtifactPaid } from '@/lib/artifacts';
/**
 * validate email token matches user signed up and it's  valid signature
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function registrationCustomerValidateEmail(store) {
  /**
   * Customer registration email validation, expects param: token
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    try {
      const { token } = to.params;
      if (!token) {
        next({ name: routeNames.ROUTE_REGISTER });
        return;
      }
      await registerCustomerApi.postVerifyEmail(token);
      to.params.user = await store.dispatch(USER_GET_PROFILE);
      next();
    } catch (error) {
      next({
        name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
        params: { errorMessage: 'An issue occurred while processing your request, your email link may have expired.' } });
    }
  };
}

/**
 * Customer link bank.
 * Check for artifactId, optional at this point.
 * route them back to register if error
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function regoLinkBank(store) {
  /**
   *
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    try {
      const { data } = await registerCustomerApi.getBankList();
      to.params.banks = data.institutions;
    } catch (error) {
      next({ name: routeNames.ROUTE_REGISTER });
    }
    let artifact;
    try {
      artifact = await store.dispatch(ARTIFACT_GET_LAST_ARTIFACT);
      if (isArtifactPaid(artifact)) {
        artifact = undefined;
      }
      to.params.artifactId = artifact?.artifactDetails?.artifactId;
    } catch (error) {
      artifact = undefined;
    }
    next();
  };
}

/**
 * Get the customer's linked bank accounts.
 * If the customer is making a payment (valid artifactId),
 * route them back to the payment request page
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function regoSelectAccount(store) {
  /**
   *
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    try {
      const { data } = await registerCustomerApi.getAccounts();
      to.params.accounts = data?.accounts;

      // we don't mind if this is undefined;
      to.params.artifactId = store?.state?.artifact?.artifactDetails?.artifactId;

      next();
    } catch (error) {
      if (error?.response?.status === STATUS_NOT_FOUND) {
        next({ name: routeNames.ROUTE_REGISTER_LINK_BANK });
        return;
      }
      next({ name: routeNames.ROUTE_REGISTER });
    }
  };
}
