<script>
import { computed, defineComponent } from '@vue/composition-api';
import Logo from '@/components/logo.vue';
import BaseStepProgress from '@/components/base/base-step-progress.vue';
import { customerRegoState } from '@/lib/registration';
import { useRoute } from '@/use/router';

export default defineComponent({
  components: { Logo, BaseStepProgress },
  name: 'RegistrationLayout',
  setup() {
    const route = useRoute();
    const steps = computed(() => customerRegoState(route.name));
    return { steps };
  },
});
</script>

<template>
  <div class="flex flex-col max-w-screen-2xl mx-auto">
    <Logo class="p-2" size="small" />
    <div class="pt-7">
      <BaseStepProgress class="lg:hidden" horizontal :steps="steps" />
    </div>
    <div class="px-2 relative">
      <BaseStepProgress class="hidden lg:block absolute" :steps="steps" />
      <slot />
    </div>
  </div>
</template>

<style scoped>
</style>
