/* eslint-disable no-param-reassign */
import { dashboard } from '@/router/middleware-merchant';

import * as routeNames from '@/lib/router';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: routeNames.ROUTE_MERCHANT_DASHBOARD,
    meta: {
      authRequired: true,
    },
    beforeEnter: dashboard(localStorage),
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Home.vue'),
  },
];

export default dashboardRoutes;
