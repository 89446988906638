const clickAwayInstances = [];
const EVENT_CLICK = 'click';
const EVENT_TOUCH_START = 'touchstart';

function addListeners(el, handler) {
  clickAwayInstances.push({ el, handler, events: [EVENT_CLICK, EVENT_TOUCH_START] });
  document.addEventListener(EVENT_CLICK, handler, false);
  document.addEventListener(EVENT_TOUCH_START, handler, false);
}

function removeListeners(el) {
  const instances = clickAwayInstances.filter((i) => i.el === el);
  instances.forEach((item) => {
    item.events.forEach((event) => {
      document.removeEventListener(event, item.handler);
    });
    const idx = clickAwayInstances.findIndex((i) => i.el === el);
    clickAwayInstances.splice(idx, 1);
  });
}

function bindDirective(el, binding, vNode) {
  if (typeof binding.value !== 'function') {
    throw Error('binding should be a function');
  }

  function clickedAway(event) {
    const { target } = event;
    if (el.contains(target) || target === el) {
      return undefined;
    }
    return binding.value.call(vNode, event);
  }
  addListeners(el, clickedAway);
}

export const DIRECTIVE_CLICK_AWAY = 'click-away';

export default {
  bind(el, binding, vNode) {
    bindDirective(el, binding, vNode);
  },

  update(el, binding, vNode) {
    removeListeners(el);
    bindDirective(el, binding, vNode);
  },

  unbind(el) {
    removeListeners(el);
  },
};
