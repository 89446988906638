/* eslint-disable no-param-reassign */
import { LAYOUT_LOGIN } from '@/lib/layouts';
import * as routeNames from '@/lib/router';
import { getArtifactDetailsIfExists } from '@/router/middleware';
import store from '@/store';

const loginRoutes = [
  {
    path: '/login',
    name: routeNames.ROUTE_LOGIN,
    meta: {
      layout: LAYOUT_LOGIN,
    },
    props: true,
    beforeEnter: getArtifactDetailsIfExists(store),
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
  },
  {
    path: '/logout',
    name: routeNames.ROUTE_LOGOUT,
    meta: {
      layout: 'logged-in-layout',
    },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_API_URL}/logout`;
    },
  },
  {
    path: '/forgot-password',
    name: routeNames.ROUTE_FORGOT_PASSWORD,
    meta: {
      layout: LAYOUT_LOGIN,
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/forgot-password.vue'),
  },
  {
    path: '/reset-password/:jwt',
    name: routeNames.ROUTE_RESET_PASSWORD,
    props: (route) => ({
      jwt: route.params.jwt,
    }),
    meta: {
      layout: LAYOUT_LOGIN,
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/reset-password.vue'),
  },
];

export default loginRoutes;
