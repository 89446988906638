<script>
import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import { useBrand } from '@/use/brand';
import NavBar from '@/components/nav-bar.vue';

export default defineComponent({
  components: { BaseIcon, NavBar },
  name: 'CustomerLoggedInLayout',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const brand = useBrand();
    return { brand };
  },
});
</script>

<template>
  <div class="relative">
    <div class="bg-dark-darker h-60 w-full" />
    <div class="absolute min-h-screen max-w-screen-lg mx-auto h-full inset-0 flex flex-col items-center">
      <nav-bar class="w-full" :user="user" />
      <div class="w-full flex flex-col flex-grow lg:px-2 lg:flex-row lg:justify-between">
        <slot />
        <div class="pt-9 px-2 lg:ml-4 md:mt-12 lg:p-0">
          <h3 class="text-xl md:text-white mb-4 md:mb-0 font-bold ml-2 lg:ml-0">Help</h3>
          <div class="flex justify-center flex-col m-2 lg:m-0">
            <a :href="brand.faqs" class="brand-contact">
              <div class="flex justify-between">
                <h3 class="text-lg">See FAQs</h3>
                <base-icon icon="fa-long-arrow-alt-right" />
              </div>
              <p class="text-sm text-shades-darker">
                Our frequently asked questions on how it works, customer enquiries and fees
              </p>
            </a>
            <div class="brand-contact">
              <h3 class="text-lg">Account Support</h3>
              <p class="text-sm text-shades-dark">We're here to help on</p>
              <p class="text-sm text-primary">{{ brand.contactPhone }}</p>
              <p class="text-sm text-primary">{{ brand.contactEmail }}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="w-full py-4 px-1">
        <p class="text-shades-dark">&copy; 2021 {{ brand.name }}</p>
      </footer>
    </div>
  </div>
</template>

<style scoped>
.brand-contact {
  @apply lg:m-4 mb-4 lg:mb-0 lg:mx-0 lg:w-60 bg-white p-4 ring-1 ring-shades rounded-lg;
}
</style>
