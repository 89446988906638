<script>
import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
/**
 * @typedef Step
 * @property {String} title - step title
 * @property {STEP_ACTIVE | STEP_PENDING | STEP_COMPLETE} status - step status
 * */
const STEP_ACTIVE = 'active';
const STEP_PENDING = 'pending';
const STEP_COMPLETE = 'complete';

export default defineComponent({
  name: 'BaseStepProgress',
  components: {
    BaseIcon,
  },
  props: {
    /**
     * Steps to render. Every step must have a title property and a status property
     *  @type {Step[]}
     */
    steps: {
      type: Array,
      required: true,
      validator(value) {
        const allowed = [STEP_ACTIVE, STEP_PENDING, STEP_COMPLETE];
        return value.every((step) => allowed.includes(step.status));
      },
    },
    /**
     * render the steps horizontally (for mobile)
     */
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<template>
  <div v-if="horizontal" class="flex justify-center">
    <div v-for="step in steps" :key="step.title">
      <div :class="['flex w-24 h-2 my-4 mx-2 horizontal-item', step.status]"></div>
    </div>
  </div>
  <div v-else class="flex flex-col">
    <div class="flex items-center" v-for="step in steps" :key="step.title">
      <div
        :class="[
          'flex rounded-full justify-center items-center w-6 h-6 my-4 mr-4 border-2 border-shades-dark circle',
          step.status,
        ]"
      >
        <span :class="['hidden icon', step.status]">
          <BaseIcon icon="fa-check" class="text-white" size="fa-xs" />
        </span>
      </div>
      <span :class="['steps-title', step.status]">{{ step.title }}</span>
    </div>
  </div>
</template>

<style scoped>
.active.circle {
  @apply border-shades-darker;
}
.complete.circle {
  @apply bg-success-dark border-none;
}
.pending.circle {
  @apply border-shades;
}
.active.horizontal-item {
  @apply bg-shades-darker;
}
.complete.horizontal-item {
  @apply bg-success-dark;
}
.pending.horizontal-item {
  @apply bg-shades-light;
}
.active.steps-title {
  @apply font-semibold;
}
.pending.steps-title {
  @apply text-shades;
}
.complete.icon {
  @apply flex;
}
</style>
