import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from '@/App.vue';
import LoadingLayout from '@/layouts/loading.vue';
import CustomerDashboardLayout from '@/layouts/customer-dashboard.vue';
import LoginLayout from '@/layouts/login.vue';
import PaymentRequestLayout from '@/layouts/payment-request.vue';
import RegistrationLayout from '@/layouts/registration.vue';
import SimpleLayout from '@/layouts/simple.vue';
import router from '@/router';
import store from '@/store';
import Brand from '@/use/brand';
import clickAway, { DIRECTIVE_CLICK_AWAY } from '@/lib/directives/click-away';
import focusTrap, { DIRECTIVE_FOCUS_TRAP } from '@/lib/directives/focus-trap';
import cleave, { DIRECTIVE_CLEAVE } from '@/lib/directives/cleave';
import {
  LAYOUT_CUSTOMER_DASHBOARD,
  LAYOUT_LOADING,
  LAYOUT_LOGIN,
  LAYOUT_PAYMENT_REQUEST,
  LAYOUT_REGISTRATION,
  LAYOUT_SIMPLE,
} from './lib/layouts';

import '@/assets/css/index.css';

Vue.use(VueCompositionAPI);
Vue.use(Brand);
Vue.directive(DIRECTIVE_CLICK_AWAY, clickAway);
Vue.directive(DIRECTIVE_FOCUS_TRAP, focusTrap);
Vue.directive(DIRECTIVE_CLEAVE, cleave);
Vue.component(LAYOUT_LOADING, LoadingLayout);
Vue.component(LAYOUT_CUSTOMER_DASHBOARD, CustomerDashboardLayout);
Vue.component(LAYOUT_LOGIN, LoginLayout);
Vue.component(LAYOUT_PAYMENT_REQUEST, PaymentRequestLayout);
Vue.component(LAYOUT_REGISTRATION, RegistrationLayout);
Vue.component(LAYOUT_SIMPLE, SimpleLayout);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
