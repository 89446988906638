<script>
import { useBrand } from '@/use/brand';
import logo from '@/components/logo.vue';

export default {
  components: { logo },
  name: 'PaymentRequestLayout',
  setup() {
    const brand = useBrand();

    return {
      brand,
    };
  },
};
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <div class="h-full flex-grow">
      <slot></slot>
    </div>
    <footer class="w-full h-20 bg-dark text-shades-darker text-sm">
      <div
        class="
          w-full
          max-w-screen-lg
          mx-auto
          h-full
          flex flex-col-reverse
          text-center
          items-center
          justify-center
          md:justify-between md:flex-row
        "
      >
        <div class="text-xs md:text-sm md:pl-2">
          <a :href="brand.about" target="_blank">About</a>
          <span class="mx-1">|</span>
          <a :href="brand.customerTerms" target="_blank">Terms</a>
          <span class="mx-1">|</span>
          <a :href="brand.privacy" target="_blank">Privacy Policy</a>
          <span class="mx-1">|</span>
          <a :href="brand.faqs" target="_blank">Frequently Asked Questions</a>
        </div>
        <div class="flex items-center mb-2 md:mb-0 md:pr-2">
          <span class="mr-2">Payments By</span>
          <logo size="small" variant="mono" />
        </div>
      </div>
    </footer>
  </div>
</template>
