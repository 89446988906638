<script>
import { computed, defineComponent } from '@vue/composition-api';
import currency from 'currency.js';
import { formatCurrency, formatDate } from '@/lib/formats';
import BaseCard from '@/components/base/base-card.vue';

export default defineComponent({
  name: 'DashboardPaymentSummary',
  components: { BaseCard },
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hasLastPayment = computed(() => currency(props?.summary?.lastRepayment?.amount).value !== 0);
    const hasUpcomingPayment = computed(() => currency(props?.summary?.nextRepayment?.amount).value !== 0);
    return {
      formatCurrency,
      formatDate,
      hasLastPayment,
      hasUpcomingPayment,
    };
  },
});
</script>

<template>
  <base-card class="flex h-36 w-96">
    <div class="w-1/2 border-r">
      <span class="text-shades-darker">Last Payment</span>
      <template v-if="hasLastPayment">
        <div class="flex flex-col mt-4">
          <span class="text-2xl font-bold">{{ formatCurrency(summary.lastRepayment.amount) }}</span>
          <span class="text-xs mt-2">{{ summary.lastRepayment.firm }}</span>
          <span class="text-xs">{{ formatDate(summary.lastRepayment.date) }}</span>
        </div>
      </template>
      <template v-else>
        <div class="mt-2">
          <span>No previous payment data</span>
        </div>
      </template>
    </div>
    <div class="w-1/2 ml-3">
      <span class="text-shades-darker">Next Payment</span>
      <template v-if="hasUpcomingPayment">
        <div class="flex flex-col mt-4">
          <span class="text-2xl font-bold">{{ formatCurrency(summary.nextRepayment.amount) }}</span>
          <span class="text-xs mt-2">{{ summary.nextRepayment.firm }}</span>
          <span class="text-xs"> {{ formatDate(summary.nextRepayment.date) }}</span>
        </div>
      </template>
      <template v-else>
        <div class="mt-2">
          <span> You have no upcoming payments </span>
        </div>
      </template>
    </div>
  </base-card>
</template>

<style scoped>
</style>
