<script>
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';

export default defineComponent({
  components: {},
  name: 'LoginLayout',
  setup() {
    const brand = useBrand();

    return {
      brand,
    };
  },
});
</script>

<template>
  <div class="flex justify-center min-h-screen">
    <div class="w-full sm:w-1/2">
      <div class="flex flex-col h-full justify-center relative w-full max-w-screen-md md:w-4/5 mx-auto px-2">
        <div class="flex-grow flex justify-center items-center w-full">
          <slot />
        </div>
        <footer class="w-full h-20 text-sm text-shades-dark">
          <a :href="brand.about" target="_blank">About</a>
          <span class="mx-1">|</span>
          <a :href="brand.customerTerms" target="_blank">Terms</a>
          <span class="mx-1">|</span>
          <a :href="brand.privacy" target="_blank">Privacy</a>
          <span class="mx-1">|</span>
          <a :href="brand.faqs" target="_blank">Frequently Asked Questions</a>
        </footer>
      </div>
    </div>
    <div class="bg-dark text-white w-1/2 hidden md:flex md:flex-col md:justify-center">
      <h1 class="text-2xl text-center">Simple Payment Plans with no hidden fees</h1>
      <div class="marketing-item">
        <img class="marketing-icon flex-shrink-0 mr-10" src="@/assets/marketing-icon-card.svg" alt="card-icon" />
        <div>
          <h3 class="font-bold">The Smarter way to pay</h3>
          <p class="">Split your payments into manageable instalments, automatically direct-debited every fortnight.</p>
        </div>
      </div>
      <div class="marketing-item">
        <img class="marketing-icon flex-shrink-0 mr-10" src="@/assets/marketing-icon-customer.svg" alt="people-icon" />
        <div class="">
          <h3 class="font-bold">Trusted by 1000s of Australians</h3>
          <span class="">As the preferred payment platform for professional services, you're in safe hands.</span>
        </div>
      </div>
      <div class="marketing-item">
        <img class="marketing-icon flex-shrink-0 mr-10" src="@/assets/marketing-icon-secure.svg" alt="secure-icon" />
        <div class="text-container">
          <h3 class="font-bold">Safe &amp; Secure</h3>
          <span class="font-base">Be confident in your transactions with bank grade, 256-bit encryption.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.marketing-item {
  @apply flex px-10 py-4 items-center;
}
.marketing-icon {
  height: 40px;
  width: 40px;
}
</style>
