/* eslint-disable import/prefer-default-export */
import { apiGet } from '@/lib/api';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/v3/artifacts`;

/**
 * Get the artifact details
 * @param {string} id - artifact id
 * @returns {Promise<import('axios').AxiosResponse<ArtifactDetails>>} ArtifactDetails
 */
export function getArtifactDetails(id) {
  const url = `${baseUrl}/${id}/details`;
  return apiGet(url);
}
