import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { checkAuth, getArtifactDetailsIfExists } from '@/router/middleware';
import * as routeNames from '@/lib/router';
import { LAYOUT_PAYMENT_REQUEST } from '@/lib/layouts';
// routes
import registrationRoutes from '@/router/routes-registration';
import loginRoutes from '@/router/routes-login';
import customerRoutes from '@/router/routes-customer';
import payRoutes from '@/router/routes-pay';
import dashboardRoutes from '@/router/routes-merchant';

Vue.use(VueRouter);

const routes = [
  // inject-routes
  ...registrationRoutes,
  ...loginRoutes,
  ...customerRoutes,
  ...payRoutes,
  ...dashboardRoutes,
  {
    path: '/something-went-wrong',
    name: routeNames.ROUTE_SOMETHING_WENT_WRONG,
    meta: {
      layout: LAYOUT_PAYMENT_REQUEST,
    },
    props: true,
    beforeEnter: getArtifactDetailsIfExists(store),
    component: () => import(/* webpackChunkName: "something-went-wrong" */ '@/views/something-went-wrong.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkAuth(store));

export default router;
