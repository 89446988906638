/* eslint-disable import/prefer-default-export */

import { apiGet, apiPost } from '@/lib/api';

const PRODUCT_10_WEEK = 'ProductType10week';

const paymentsBaseUrl = `${process.env.VUE_APP_API_URL}/api/v3/payments`;
const decisionsBaseUrl = `${process.env.VUE_APP_API_URL}/api/v3/credit-decisions`;

/**
 * Get Payment Plan decisions by artifact id, associated to a customer.
 * @param {Object?} payload decisions payload
 * @param {string} payload.artifactId artifact id
 * @param {string} payload.artifactType artifact type (Invoice, etc)
 * @param {string} payload.partyId customer id
 * @returns
 */
export function getPaymentPlanDecisionsByArtifactId(
  { artifactId, artifactType, partyId } = { artifactId: '', artifactType: '', partyId: '' },
) {
  const url = `${decisionsBaseUrl}?customer_id=${partyId}&customer_type=user&artifact_id=${artifactId}&artifact_type=${artifactType}`;
  return apiGet(url);
}

/**
 * Request a Payment Plan assessment for a customer
 * @param {Object?} payload decisions payload
 * @param {string} payload.artifactId artifact id
 * @param {string} payload.artifactType artifact type (Invoice, etc)
 * @param {string} payload.partyId customer id
 * @returns
 */
export function requestPaymentPlanAssessment(
  { artifactId, artifactType, partyId } = { artifactId: '', artifactType: '', partyId: '' },
) {
  const url = `${decisionsBaseUrl}?customer_id=${partyId}&customer_type=user&artifact_id=${artifactId}&artifact_type=${artifactType}`;
  return apiPost(url, { artifactId });
}

/**
 * Confirm a Payment Plan
 * @param {string} payload BNPL payload
 * @returns
 */
export function confirmPaymentPlan(artifactId) {
  const url = `${paymentsBaseUrl}/bnpl`;
  return apiPost(url, { artifactId, productType: PRODUCT_10_WEEK });
}
