<script>
import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import Avatar from '@/components/avatar.vue';
import Logo from '@/components/logo.vue';
import AccountSwitcher from './account-switcher.vue';

export default defineComponent({
  name: 'NavBar',
  components: { BaseIcon, Avatar, Logo, AccountSwitcher },
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  setup(_, { emit }) {
    function emitSearchTerm(event) {
      emit('search-term', event.target.value);
    }
    return { emitSearchTerm };
  },
});
</script>

<template>
  <div class="flex justify-between py-8 md:px-2">
    <logo variant="reverse" />
    <AccountSwitcher :profile="user" />
  </div>
</template>

<style scoped></style>
