import { apiPost } from '@/lib/api';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/v3`;

/**
 * login request
 * @param {Object} params login object
 * @param {string} params.email user's email
 * @param {string} params.password user's password
 * @returns
 */
function postLogin(params = {}) {
  const url = `${baseUrl}/login`;
  return apiPost(url, params);
}
/**
 * log out user
 * @returns
 */
function postLogout() {
  const url = `${baseUrl}/logout`;
  return apiPost(url);
}

/**
 * request a password reset email to be sent
 * @param {string} email user's email
 * @returns
 */
function postPasswordReset(email = '') {
  const url = `${baseUrl}/forgot-password`;
  return apiPost(url, { email });
}

/**
 * reset user's password (generate email)
 * @param {Object} payload password and token payload
 * @param {string} payload.password password
 * @param {string} payload.passwordConfirm confirm password
 * @param {string} payload.token token received in email
 * @returns
 */
function resetPassword(payload) {
  const url = `${baseUrl}/reset-password`;
  return apiPost(url, payload);
}

/**
 * update user's password without requirement for an email or jwt
 * @param {Object} payload password payload
 * @param {string} payload.password1 password (password1)
 * @param {string} payload.password2 password (password2)
 * @returns
 */
function updatePassword(payload) {
  const url = `${baseUrl}/users/update-password`;
  return apiPost(url, payload);
}

export default {
  postLogin,
  postLogout,
  postPasswordReset,
  updatePassword,
  resetPassword,
};
