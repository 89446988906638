<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import Avatar from '@/components/avatar.vue';
import BaseIcon from './base/base-icon.vue';
import { PARTY_TYPE_PERSON } from '@/lib/user';
import { ROUTE_CUSTOMER_SETTINGS, ROUTE_LOGIN } from '@/lib/router';
import { useRouter } from '@/use/router';
import authApi from '@/api/auth';

export default defineComponent({
  name: 'AccountSwitcher',
  components: { Avatar, BaseIcon },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const showMenu = ref(false);

    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }

    async function logoutUser() {
      await authApi.postLogout();
      router.push({ name: ROUTE_LOGIN });
    }

    function onClickAway() {
      showMenu.value = false;
    }

    const renderName = computed(() => {
      if (props?.profile?.partyType === PARTY_TYPE_PERSON) {
        return `${props?.profile?.firstName} ${props?.profile?.lastName}`;
      }
      return props?.profile?.partyName || '';
    });

    const renderAccountType = computed(() => {
      if (props?.profile?.partyType === PARTY_TYPE_PERSON) {
        return 'Customer account';
      }
      return 'Business account';
    });

    const renderDropdownIcon = computed(() => (showMenu.value ? 'fa-chevron-up' : 'fa-chevron-down'));

    return {
      renderName,
      renderAccountType,
      renderDropdownIcon,
      showMenu,
      toggleMenu,
      logoutUser,
      onClickAway,
      ROUTE_CUSTOMER_SETTINGS,
    };
  },
});
</script>

<template>
  <div class="inline-block relative" v-click-away="onClickAway">
    <div @click="toggleMenu" class="inline-flex items-center cursor-pointer">
      <Avatar />
      <div class="flex flex-col md:px-1 ml-1 items-center text-white">
        <span class="">{{ renderName }}</span>
        <span class="text-xs">{{ renderAccountType }}</span>
      </div>
      <BaseIcon class="text-white md:ml-1" :icon="renderDropdownIcon" />
    </div>
    <div
      class="cursor-pointer absolute mt-2 top-full border border-shades-lighter p-3 w-full z-10 shadow-md bg-white"
      v-show="showMenu"
    >
      <ul class="text-xs">
        <li class="pb-4"><router-link :to="{ name: ROUTE_CUSTOMER_SETTINGS }">Account settings</router-link></li>
        <hr />
        <li class="pt-4 pb-1">
          <a @click="logoutUser">
            Logout
            <BaseIcon class="ml-1" icon="fa-sign-out-alt" size="fa-lg" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
</style>
