/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { isArtifactPaid } from '@/lib/artifacts';
import { ROUTE_LOGIN } from '@/lib/router';
import { ARTIFACT_GET_LAST_ARTIFACT, USER_GET_PROFILE } from '@/lib/store';

/**
 *
 * @param {import('vuex').Store} store
 * @returns
 */
export function checkAuth(store) {
  /**
   * Check auth scope per route
   * If not authorised, redirect to login
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function checkAuthOrRedirect(to, from, next) {
    if (!to?.meta?.requiresAuth) {
      // non protected route
      next();
      return;
    }

    try {
      await store.dispatch(USER_GET_PROFILE);
      next();
    } catch (error) {
      next({ name: ROUTE_LOGIN });
    }
  };
}

/**
 * middleware for route: customer
 * Check a user has a profile, if they do, route next. If they do not have a profile, route away to a designated route.
 * On error, route to login.
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function getCustomerProfileOrRouteTo(store, routeTo = undefined) {
  return async function beforeEnter(to, from, next) {
    try {
      const user = await store.dispatch(USER_GET_PROFILE);
      to.params.user = user;
      next();
    } catch (error) {
      if (!routeTo) {
        next({ name: ROUTE_LOGIN });
        return;
      }
      next({ name: routeTo });
    }
  };
}

/**
 * if an artifact has been previously viewed and is in a payable state, fetch and attach to prop: artifactDetails
 * @param {import('vuex').Store} store vuex store
 * @returns
 */
export function getArtifactDetailsIfExists(store) {
  /**
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router').NavigationGuardNext} next
   */
  return async function beforeEnter(to, from, next) {
    let artifact;
    try {
      artifact = await store.dispatch(ARTIFACT_GET_LAST_ARTIFACT);
      if (isArtifactPaid(artifact)) {
        artifact = undefined;
      }
    } catch (error) {
      artifact = undefined;
    }
    to.params.artifactDetails = artifact?.artifactDetails;
    next();
  };
}
