<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Avatar',
  props: {
    logoUri: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {},
});
</script>

<template>
  <figure v-if="logoUri" class="avatar-container">
    <img class="avatar-container" :src="logoUri" alt="merchant logo" />
  </figure>

  <span
    data-testid="customer-avatar"
    v-else
    class="inline-block avatar-container bg-gradient-to-br from-primary-light to-primary-dark"
  />
</template>

<style scoped>
.avatar-container {
  @apply h-10 w-10 rounded-full;
}
</style>
